// Materialize styles
@import "materialize";

@font-face {
    font-family: 'Raleway-Light';
        src: url('fonts/Raleway-Light.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway-Medium';
        src: url('fonts/Raleway-Medium.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
        src: url('fonts/Raleway-Regular.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

html {
  font-family: 'Raleway-Light';
  text-transform: none;
}

h1, h2, h3, h4, h5, h6{
    text-transform: uppercase;
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 17px;
    text-transform: none;
    text-align: justify;
    line-height: 24px;
    margin-top: 0px;
}

h4 {
    font-size: 20px;
    line-height: 30px;
    text-transform: none;
}

h5 {
    font-size: 23px;
    margin-bottom: 25px;
    font-family: 'Raleway';
}

h6 {
    font-size: 19px;
}

p{
  text-transform: none;
  font-size: 15px;
}

.partitle{
  font-size: 16px;
  font-family: 'Raleway-Medium';
}

ul.kutlijst li{
  list-style-type: disc;
}

.progress{
    z-index: 996;
    position: fixed;
    margin: 0;
    height: 3px;
    margin-top: 24px;
}

.quick-header{
    color: white;
    z-index: 996;
    position: fixed;
    width: 100%;
    font-size: 16px;
}

.fabb-main{
    // margin-top:19px;
    bottom: 0;
}

.aboutmainimage{
  width: 100%;
  height: 600px;
  object-fit: cover;
  background-position: center center;
  overflow: hidden;
}

.fabb.carousel{
  width: 100%;
}

.carouselimage{
  width:100%;
}

.btn.right{
  background-color: #004772;
}

.btn{
  background-color: #004772;
}

.btn:hover{
  background-color: #0277bd;
}

.btn-large{
  background-color: #004772;
}

.btn-back{
  margin-top: 20px;
}

.abouthor{
  max-width:100%;
  max-height:100%;
}

.row .aboutpadding{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.freetrypadding{
  padding-top: 30px;
  padding-bottom: 10px;
}

.row .bottomrectangle{
  height: 50px;
  position: absolute;
  bottom: 0;
}

.select-size-box{
}

.freeflowicon {
  height: 100px;
}

@media #{$medium-and-up} {
    html{
        font-size: 15px;
    }

}

@media #{$small-and-down} {
    html{
        font-size: 13px;
    }

    h2{
        font-size: 30px;
    }

    h3 {
        font-size: 15px;
        text-align: center;
    }
}

.header{
    margin-left: 10px;
}

.container{
  min-height: 500px;
	width: 99%;
}



nav {
    @extend .white;
}

nav a{
   @extend .black-text;
 }

nav .brand-logo {
    margin-left: 1%;
    margin-top: 10px;
}

.brand-logo img{
    width: 70px;
}

.social-logo {
    width: 30px;
    margin-top: 15px;
}

.nav-wrapper{
    font-family: 'Raleway';
    text-transform: uppercase;
}

.countrySelector{
    color:#9e9e9e;
}

.row .textarea{
	padding-left: 0px;
}

.card .card-image .card-title .right{
    float: right;
}
.card-subscript{
    font-family: 'Raleway-Medium';
    font-size: 0.9rem;
    text-transform: none;
    float: right;
}

.rowabout {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
}

.card .card-content{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 12px;
}
.card .card-title{
    text-transform: uppercase;
    font-size: 1.3rem;
}

.parallax-container {
  height: 600px;
}

.product-details-caroussel{
	width: 100%;
}

.fabb-carousel{
  display: block;
  position: relative;
}
.fabb-carousel-overlay{
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  z-index: 2;
}

.fabb-carousel-push-down{
  height: 410px;
}

.fabb-carousel-overlay-title{
  background-color: rgba(255, 255, 255, 0.4);
}

.fabb-carousel-overlay-subscript{
  background-color: rgba(255, 255, 255, 0.4);
}

.place-autocomplete--input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #9e9e9e;
}
.place-autocomplete--input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #9e9e9e;
   opacity:  1;
}
.place-autocomplete--input::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #9e9e9e;
   opacity:  1;
}
.place-autocomplete--input:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #9e9e9e;
}

.cart-table-td label {
    display: none;
}

.cart-table-td-select{
    margin-top: 12px;
}
.cart-table-td-div-image-text{
    text-align: center;
    font-size: 1rem;
    text-transform: uppercase;
}

.cart-table-td .select-wrapper {
    margin-top: 22.5px;
}

.cart-table-td-div-image {
    margin: 0 auto;
    width: 100px;
    // margin-left: 0px;
    // margin-right: -70px;
}


.cart-table-td-div-image img {
    width: 100%;
}

.checkout-total-hd {
    font-weight: bold;
    text-align: left;
}

.checkout-total-gran-total {
    font-weight: bold;
    text-align: left;
    font-size: large;
}

.checkout-total-d {
    float: right;
    margin-right: 25%;
}

.menu-shopping-cart{
    margin-right: 2%;
    padding-top: 10px
}


.toast {
    background-color: #0277bd;
    text-transform: uppercase;
}

.menu-locale-selector{
    margin-right: 15px;
    margin-left: 10px;
}

i.locale-selector-arrow.material-icons.right{
    margin-left: 0px;
}

.select-dropdown, .dropdown-content, .dropdown-content > li{
    text-transform: none;
     font-size: 0.5 rem;
}
/*!
 * Generated with CSS Flag Sprite generator (https://www.flag-sprites.com/)
 */

.flag {
    display: inline-block;
    width: 16px;
    height: 11px;
    background: url('images/flags.png') no-repeat;
}


.flag.flag-nl {
    background-position: 0 -11px;
}

.flag.flag-be {
    background-position: 0 0;
}

.flag.flag-um {
    background-position: -16px -11px;
}

.flag.flag-fr {
    background-position: -16px 0;
}

.flag.flag-gb {
    background-position: -32px 0;
}

.modal{
    overflow-y: visible; //in case you want a select box in the modal this fixes it
    width:40%;
}

.paymentlogos {
  height: 30px;
  margin-right: 1%;
}

#sidenav-overlay{
    z-index: 996;
}

.parallax img {
    bottom: -130px;
}

.about-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 300px;
  max-width: 100%;
  padding-top: 20px;
}

.about-accent {

}
.swiper-button{
  height: 55px;
}

.swiper-button-next{
  background: url(images/swiper-button-next.svg);
}

.swiper-button-prev{
  background: url(images/swiper-button-prev.svg);
}

.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background: white;
}

.swiper-pagination-bullet-active {
    opacity: 3;
    background: #e81b1b;
}

@media screen and (max-width: 1150px) {
  .fabb-carousel-push-down{
    height: 380px;
  }
}

@media screen and (max-width: 1050px) {
  .fabb-carousel-push-down{
    height: 320px;
  }
  .fabb-carousel h1 {
    font-size: 24px;
  }
}

@media screen and (max-width: 870px) {
  .fabb-carousel-push-down{
    height: 270px;
  }
  .fabb-carousel h1 {
    font-size: 22px;
  }
}

@media #{$medium-and-down}{
    .progress{
        margin-top: 16px;
    }
    .fabb-main{
        margin-top:15px;
    }
    .quick-header{
        font-size: 11px;
        font-family: 'Raleway-Medium';
    }

    .parallax img {
        bottom: -120px;
    }
    .container{
      width: 90%;
    }

}

@media screen and (max-width: 800px) {
  .fabb-carousel-push-down{
    height: 0px;
  }
  .fabb-carousel-overlay-subscript{
    position: absolute!important;
    bottom: 0;
  }
  .fabb-carousel h1 {
    font-size: 22px;
  }
}

@media #{$small-and-down} {
    .parallax img {
        bottom: -150px;
    }
    .parallax-container {
      height: 350px;
    }

  .cart-table-td-div-image{
      width: 50px;
  }

  .card .card-title{
      font-size: 1.3rem;
  }

  .container{
  	width: 90%;
  }

  .card-image{
      cursor: pointer;
  }

  .brand-logo img{
      width: 60px;
      margin-top: 0px;
  }

  .modal{
      width: 90%;
  }

  .checkout-error-toast{
      background-color: red;
  }

  .brand-logo{
      left: 40% !important;
  }

  .menu-locale-selector{
      margin-right: 10%;
  }

  .paymentlogos {
    height: 23px;
    margin-right: 1%;
  }
  .menu-shopping-cart{
      margin-right: 4%;
      padding-top: 10px
  }
  .aboutmainimage{
    height: 300px;
    object-fit: cover;
    background-position: center center;
    overflow: hidden;
  }

  .cactus{
    height: 150px;
    object-fit: cover;
    background-position: center center;
    overflow: hidden;
  }

  .fabb-carousel{
    height: 300px;
    width: 100%;
    overflow: hidden;
  }

  .carouselimage{
    height: 300px;
    width: 100%;
    object-fit: cover;
    background-position: center center;
  }

  .fabb-carousel-push-down{
    height: 220px;
  }

  .fabb-carousel h1 {
    font-size: 12px;
    font-family: raleway;
  }

  .fabb-carousel-overlay-subscript{
  background-color: rgba(255, 255, 255, 0.5);
}

}

@media all and (max-width: 350px){
    .menu-locale-selector {
        margin-right: 10px;
    }
}

.cookies-dialog {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0px;
    font-size: 12px;
    text-transform: none;
}

.fabb-loading-hider{
  z-index: 100000;
  height: 2000px;
  background-color: white;
  width: 100%;
  position: fixed;
}

.fabb-loading-hider img{
  height: 8%;
  display: block;
  margin: auto;
}

.fabb-loading-hider-almost {
  visibility: hidden;
}

.spinner {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}
